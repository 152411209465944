.js-tooltip {
    display: inline-block;

    svg {
        pointer-events: none;
    }
}

.popover{
    cursor: pointer;
}
.tooltip-link{
  border-bottom: $tooltip-link-border-width $tooltip-link-border-style color($tooltip-link-border-color);
  display: inline;
  text-decoration: none;
  &:link, &:active, &:hover, &:visited, &:focus{
    color: color($tooltip-link-color);
  }
}
.tooltip{
  @include body-copy-text;
  font-size: font-size('sm');
  box-shadow: shadow($tooltip-shadow);
  background-color: color($tooltip-background-color);
  border: border($tooltip-border);
  border-radius: 4px;
  padding: .3rem .6rem;
}

.tooltip-popper{
  position: absolute;
  max-width: 66ch;
}

.tooltip-popper .tooltip-arrow {
  position: absolute;
}
.tooltip-popper .tooltip-arrow.down{
    border-top:8px solid border-color($tooltip-border);
    border-right:8px solid transparent;
    border-left:8px solid transparent;
    bottom: -7px;
    left: calc(50% - 8px);

    &:after{
        content: '';
        border-top: 7px solid color($tooltip-background-color);
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        display: block;
        position: absolute;
        bottom: 1px;
        margin-left: -7px;
    }
}
.tooltip-popper .tooltip-arrow.up{
    border-bottom:8px solid border-color($tooltip-border);
    border-right:8px solid transparent;
    border-left:8px solid transparent;
    top: -7px;
    left: calc(50% - 8px);
    &:after{
        content: '';
        border-bottom: 7px solid color($tooltip-background-color);
        border-right: 7px solid transparent;
        border-left: 7px solid transparent;
        display: block;
        position: absolute;
        top: 1px;
        margin-left: -7px;
    }
}