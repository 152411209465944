/*
----------------------------------------
Alert
----------------------------------------
*/
$custom-alerts: () !default;
$custom-alerts-bar: () !default;

$alerts: (
  success: $alert-success-background-color,
  warning: $alert-warning-background-color,
  error: $alert-error-background-color,
  info: $alert-info-background-color
);

$alerts-bar: (
  success: $alert-success-border-color,
  warning: $alert-warning-border-color,
  error: $alert-error-border-color,
  info: $alert-info-border-color
);

$alerts: map-merge($alerts, $custom-alerts);
$alerts-bar: map-merge($alerts-bar, $custom-alerts-bar);

.alert {
  background-color: color($alert-background-color);
  background-position: 16px 16px;
  background-repeat: no-repeat;
  background-size: 24px;
  padding-bottom: units($alert-padding);
  padding-left: units($alert-padding);
  padding-right: units($alert-padding);
  padding-top: units($alert-padding);
  position: relative;
  margin-top: units(4);
  margin-bottom: units(4);

  border-left: units($alert-border-size) solid color($alert-border-color);

  &:first-child {
    margin-top:0;
  }

  &.alert--paragraph {
    max-width: $typography-text-max-width;
  }
  .alert-close{
    @include button-unstyled();
    cursor:pointer;
    position: absolute;
    top: units(4);
    right: units(4);
    @include small-text;
    color: color($alert-close-text-color);
    text-decoration: underline;
    .icon-svg{
      margin-right: units(2);
      width: 1.6rem;
      height: 1.6rem;
      fill: currentColor;
    }
  }

  .alert-heading {
    @include responsive-style($alert-heading-fontsize, font-size);
    @include responsive-style($alert-heading-lineheight, line-height);
    margin-top: 0;
    margin-bottom: 0;
    font-weight: font-weight("semibold");
    color: color($alert-heading-color);
    -ms-word-wrap: break-word;
    word-wrap: break-word;
  }

  .alert-text {
    margin-bottom: 0;
    margin-top: 0;
    color: color($alert-text-color);
    max-width: $typography-text-max-width;
    a, a:link, a:active, a:visited, a:hover{
      color: color($alert-text-color);
    }
  }
}

@each $name, $bgcolor in $alerts {
  .alert-#{$name} {
    background-color: color($bgcolor);
    border-color: color(map-get($alerts-bar, $name));

    &.alert--show-icon{
      $alert-icon: $name;
      @if $name == error {
        $alert-icon: 'highlight-off';
      }
      
      @if $name == success {
        $alert-icon: 'check-circle';
      }
      
      @if $name == warning {
        $alert-icon: 'report-problem';
      }
      background-image: url($icons-folder-path+'/'+$alert-icon+'.svg');
      padding-left: calc(#{units($alert-padding)} + #{$alert-icon-size} + 8px);
    }
  }
}

.alert + .alert{
  margin-top: units(4);
}
