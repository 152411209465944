/*
----------------------------------------
Accordion
----------------------------------------
*/
.accordion-button {
  @include button-unstyled;
  background-color: color($accordion-button-background-color-default);
  background-image: url($icons-folder-path+'/'+$accordion-button-closed-icon);
  background-position: left units(4, 'rem') center;
  background-repeat: no-repeat;
  background-size: units($accordion-button-icon-size, 'rem');
  color: color($accordion-button-color-default);
  cursor: pointer;
  display: inline-block;
  margin: 0;
  line-height: units($typography-body-text-line-height, 'rem');
  padding: units(4, 'rem') units(4, 'rem') units(4, 'rem') units(9, 'rem');
  width: 100%;
  font-size: font-size($typography-body-text-font-size);
  position: relative;
  transition: none;
  border-radius: $accordion-button-border-radius;
  border: border($accordion-content-border);

  &:hover {
    background-color: color($accordion-button-background-color-hover);
    color: color($accordion-button-color-hover);
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin: 0;
  }
  .icon_text {
    font-weight: font-weight("semibold");
    margin-right: units(3);
    float: left;
  }
  .accordion-icon {
    right: units(4, 'rem');
    height: units(5, 'rem');
    line-height: units(5, 'rem');
    position: initial;
    text-align: left;
    transform: none;
    display: block;
    top: 0.8rem;
   @include media-breakpoint-up(md) {
      text-align: center;
      position: absolute;
      transform: translateY(-50%);
      top: 50%;
      display: flex;
   }

    .icon-svg {
      fill: color($accordion-button-color-default);
      float: left;
    }
    &:hover .icon-svg{
      fill: color($accordion-button-color-hover);
    }
  }
}

button.accordion-bulk-button{
  @extend .function-link;
  
  background-repeat: no-repeat;
  background-position: left top;
  padding-left:units(505);
  margin-bottom: units(4);
  
  &[data-accordion-bulk-expand="true"] {
    background-image: url($icons-folder-path+'/'+$accordion-button-open-icon);
  }
  &[data-accordion-bulk-expand="false"] {
    background-image: url($icons-folder-path+'/'+$accordion-button-closed-icon);
  }
}

.accordion {
  @include unstyled-list;
  margin: 0;
  padding: 0;
  width: 100%;
  > ul li ul {
    list-style: disc;
    > li > ul {
      list-style: circle;
      > li > ul {
        list-style: square;
      }
    }
  }

  + .accordion {
    margin-top: units(3, 'rem');
  }
  > li {
    list-style: none;
    margin-bottom: units(3, 'rem');
    width: 100%;
    > h1, > h2, > h3, > h4, > h5, > h6{
      margin-top: 0px;
      margin-bottom: 0px;
      letter-spacing: normal;
      max-width: none;
    }
  }
}

.accordion-content {
  background-color: color($accordion-content-background-color);
  color: color($accordion-content-color);
  overflow: auto;
  padding: units(4, 'rem');
  border-bottom: border($accordion-content-border);
  border-left: border($accordion-content-border);
  border-right: border($accordion-content-border);
  border-radius: $accordion-content-border-radius;
  p, h1, h2, h3, h4, h5, .h1, .h2, .h3, .h4, .h5{
    color: color($accordion-content-color);
  }

  > *:first-child {
    margin-top: 0;
  }

  > *:last-child {
    margin-bottom: 0;
  }

  &:not([aria-hidden]) {
      display: none;
  }

  &[aria-hidden=true] {
    display: none;
  }
}
.accordion-button.accordion-error {
  border: $accordion-button-error-border-width solid color($accordion-button-error-border-color);
}
.accordion-button[aria-expanded=true] {
  background-color: color($accordion-button-open-background-color);
  border-radius: $accordion-button-open-border-radius;
  &:not(.accordion-error){
    border-bottom: border($accordion-button-open-border);
  }
}
.accordion-button[aria-expanded=false] {
  background-image: url($icons-folder-path+'/'+$accordion-button-open-icon);
  background-repeat: no-repeat;
  background-size: units($accordion-button-icon-size, 'rem');
  border-radius: $accordion-button-border-radius;
}