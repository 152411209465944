// Tags
.tag{
  appearance: none;
  display: inline-block;
  width: auto;
  padding:0;
  padding-left: units(3);
  padding-right:units(3);
  margin-right:units(3);
  margin-bottom:units(3);
  border-radius: $tag-border-radius;
  border: none;
  font-size: font-size($tag-font-size);
  font-weight: font-weight($tag-font-weight);
  color: color($tag-text-color);
  background-color: color($tag-default-background-color);
  @include font-smoothing;
  line-height: units($tag-line-height, rem);
  text-align: center;
  text-decoration: none;
  cursor: pointer;
  transition: all .2s ease-in-out;

  &.tag-icon {
    padding:0;
    padding-left:units(3);
  }

  & + .tag {
    margin-bottom: units(3);
  }

  &:hover,
  &.tag-hover {
    background-color: color($tag-hover-background-color);
  }

  &:active,
  &.tag-active {
    background-color: color($tag-active-background-color);
  }

  &.focus,
  &:focus {
    background-color: color($tag-focus-background-color);
  }

  &.focus,
  &:focus {
    @include focus();
    position: relative;
  }

  .icon-svg {
    float: right;
    margin: 0px 0px 0px 5px;
    fill: color($tag-icon-color);
  }
}

.button-row {
  display: flex;
  margin: 2rem 0 1rem;
}