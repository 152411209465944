$icons: (
    "accessibility-new",
    "account-circle",
    "add-a-photo",
    "add",
    "agriculture",
    "apps",
    "arrow-back",
    "arrow-downward",
    "arrow-drop-down",
    "arrow-drop-up",
    "arrow-forward",
    "arrow-left",
    "arrow-right",
    "arrow-upward",
    "assessment",
    "assignment",
    "attach-file",
    "bookmark-border",
    "bookmarks",
    "cached",
    "calculator",
    "card-account-details",
    "card-text",
    "chat",
    "check-circle-white",
    "check-circle",
    "chevron-double-left",
    "chevron-double-right",
    "chevron-left",
    "chevron-right",
    "close",
    "construction",
    "contact-support",
    "content-copy",
    "coronavirus",
    "create-new-folder",
    "credit-card",
    "database",
    "desktop-windows",
    "domain",
    "done",
    "download-done",
    "drive-eta",
    "email",
    "engineering",
    "expand-less",
    "expand-more-white",
    "expand-more",
    "face",
    "feedback",
    "file-download",
    "file-excel",
    "file-image",
    "file-pdf",
    "file-upload",
    "file-word",
    "first-page",
    "flag",
    "folder-open",
    "folder",
    "format-align-center",
    "format-align-justify",
    "format-align-left",
    "format-align-right",
    "format-bold",
    "format-clear",
    "format-color-fill",
    "format-color-text",
    "format-indent-decrease",
    "format-indent-increase",
    "format-italic",
    "format-list-bulleted",
    "format-list-checks",
    "format-list-numbered",
    "format-size",
    "format-underlined",
    "forum",
    "forward",
    "fullscreen-exit",
    "fullscreen",
    "grade-outline",
    "grade",
    "groups",
    "headphones",
    "help",
    "highlight-off-white",
    "highlight-off",
    "home",
    "hourglass-empty",
    "how-to-reg",
    "info-white",
    "info",
    "insert-drive-file",
    "insert-invitation",
    "language",
    "laptop",
    "last-page",
    "link",
    "list-alt",
    "local-shipping",
    "location-on",
    "lock-open",
    "lock",
    "map",
    "menu",
    "mode",
    "more-horiz",
    "more-vert",
    "mouse",
    "note-add",
    "notifications",
    "open-in-new",
    "open-with",
    "outlined-flag",
    "palette",
    "payments",
    "people-alt",
    "person-add-alt-1",
    "person-remove",
    "person",
    "phone-iphone",
    "photo-camera",
    "playlist-add-check",
    "playlist-add",
    "print",
    "query-builder",
    "refresh",
    "remove",
    "reply",
    "report-problem",
    "save",
    "school",
    "science",
    "search",
    "send",
    "settings",
    "share",
    "shopping-cart",
    "sort-ascending",
    "sort-by-alpha",
    "sort-default",
    "sort-descending",
    "sort-table-ascending",
    "sort-table-descending",
    "sort-table-none",
    "sort",
    "subdirectory-arrow-left",
    "subdirectory-arrow-right",
    "support-agent",
    "text-format",
    "trash-can",
    "tune",
    "vertical-split",
    "videocam",
    "view-headline",
    "visibility-off",
    "visibility",
    "zoom-in",
    "zoom-out"
) !default;

.icon{
    height: units($typography-body-text-line-height, 'rem');
    width: units($typography-body-text-line-height, 'rem');
    background-repeat: no-repeat;
    display: inline-block;
    background-size: 100%;
}
.icon-svg {
    height: units($typography-body-text-line-height, 'rem');
    width: units($typography-body-text-line-height, 'rem');
    vertical-align: middle;
    &--inherit-color{
        fill: currentColor; //svg inherit color
    }
    &:focus{
      outline: none;
    }
}
.inline-svg{
  height: 1em;
  width: 1em;
}

.icon-svg-large {
    height: font-size($typography-display-1-heading-font-size);
    width: font-size($typography-display-1-heading-font-size);
    vertical-align: middle;
    &--inherit-color{
        fill: currentColor;
    }
}

@each $icon in $icons {
    .icon-#{$icon}:not(a) {
        background-image: url($icons-folder-path+'/'+$icon+".svg");
    }
}

.hide-base-svg{ //hide inline svgs
    display: none;
}
