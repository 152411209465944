footer {

  p, h1, h2, h3, h4, h5, h6 {
    margin:0;
  }
  .h1, .h2, .h3, .h4, .h5, .h6 {
    margin:0;
  }


  //generel for footer
  .footer {
    border-top: border($footer-border);
    background-color: color($footer-background-color);
    padding-top: calc(#{units(5)} - 1px);
    padding-bottom: units(4);
    margin-top: 8rem;
    color: color($footer-text-color);
    @include media-breakpoint-up(md) {
      padding-bottom: calc(#{units(9)} + #{units(4)});
    }
    p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
      color: color($footer-text-color);
    }

    .function-link {
      color: color($footer-text-color);
      &:hover, &:focus, &:visited{
        color: color($footer-text-color);
      }
    }

    .footer-col {
      &:not(:first-child) {
        margin-top: units(5);
      }
      @include media-breakpoint-up(md) {
        &:not(:first-child) {
          margin-top: 0;
        }
      }
    }

    .logo{
      background-image: url($image-path+'/'+$footer-logo);
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      height: auto;
      max-height: 100%;
      max-width: 150px;
    }

    ul{
      &:not(:last-child) {
        margin-bottom: units(5);
      }

      &.footer-links {
        li {
          margin-top:units(3);
        }
      }
    }

    .inline-list {
    > li:not(:last-child) {
        &:after {
          @include media-breakpoint-up(md) {
            content: "\00b7" !important;
            margin-left: units(3);
            margin-right: units(3);
          }
        }
      }
    }
  }
  //footer-theme
  .footer-theme {
    padding-top: units(4);
    padding-bottom: units(3);
  }
}
