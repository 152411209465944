// Focus state mixin

@mixin focus-outline {
  outline: $theme-focus-width $theme-focus-style color($theme-focus-color);
}
@mixin focus-offset {
  outline-offset: $theme-focus-offset;
}

@mixin focus {
  @include focus-outline();
  @include focus-offset();
}

// Screen reader only helper
@mixin sr-only() {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
  left: -999em;
}

// This "negates" the sr-only() helper; useful if
// we want the default state of something to be
// for screen readers only, but we want to force
// it to show for sighted users under more specific
// conditions.
@mixin not-sr-only() {
  position: static;
}

.sr-only {
  @include sr-only();
}

// Unstyled list helper
@mixin unstyled-list() {
  margin: 0;
  list-style-type: none;
  padding-left: 0;

  > li {
    margin-bottom: 0;
    margin-top: 0;
    padding-left: 0;
    &::before {
      content: "" !important;
      padding-right: 0 !important;
    }
  }
}

// nobullet list helper
@mixin nobullet-list() {
  list-style-type: none;
  padding-left: 0;
  > li {
    padding-left: 0;
    &::before {
      content: "" !important;
      padding-right: 0 !important;
    }
  }
}
// noindent list helper
@mixin noindent-list() {
  > li {
    padding-left: calc(8px + 6px);
    &::before {
      left: 0px;
    }
  }
}

// Font smoothing mixin
@mixin font-smoothing {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}


/*
----------------------------------------
responsive-style
----------------------------------------
Sets styles within breakpoints
----------------------------------------
*/
@mixin responsive-style($map, $style){
  @if type-of($map) == 'map' {
    @each $breakpoint, $value in $map{
      @if $breakpoint == "default" {
        @if $style == line-height{
          #{$style}: units($value, 'rem');
        }@else if $style == font-size{
          #{$style}: font-size($value);
        }@else{
          #{$style}: $value;
        }
      } @else{
        @include media-breakpoint-up($breakpoint) {
          @if $style == line-height{
            #{$style}: units($value, 'rem');
          }@else if $style == font-size{
            #{$style}: font-size($value);
          }@else{
            #{$style}: $value;
          }
        }
      }
    }
  } @else{
    @if $style == line-height{
      #{$style}: units($map, 'rem');
    }@else if $style == font-size{
      #{$style}: font-size($map);
    }@else{
      #{$style}: $map;
    }
  }
}