/*
----------------------------------------
Virk theme
----------------------------------------
*/
$theme-colors: (
    "virk": (
        100: #0059B3,
        200: #004993,
        300: #003972
    )
);

$theme-color-primary: 'virk-100';
$theme-color-primary-dark: 'virk-200';
$theme-color-primary-darker: 'virk-300';
$header-portal-logo: 'logo_virk.svg';
$header-portal-logo-height: 4.8rem;
$header-portal-logo-width: 7.8rem;
$header-portal-compact-logo-width: 3.8rem;

