$toast-backgrounds: (
    success: $toast-success-background-color,
    warning: $toast-warning-background-color,
    error: $toast-error-background-color,
    info: $toast-info-background-color
);

$toast-icon-names: (
    success: $toast-success-icon,
    warning: $toast-warning-icon,
    error: $toast-error-icon,
    info: $toast-info-icon
);

$toast-icon-backgrounds: (
    success: $toast-success-color,
    warning: $toast-warning-color,
    error: $toast-error-color,
    info: $toast-info-color
);

.toast-container {
    position: fixed;
    right: units(4);
    left: units(4);
    top: units(4);
    bottom: auto;
    z-index: 7000;

    @include media-breakpoint-up(md) {
        right: units(4);
        left: auto;
        top: units(8);
        bottom: auto;
    }
}

.toast {
    width: 100%;
    max-width: 100%;
    background-color: color($toast-default-background-color);
    display: flex;
    transition: opacity .15s linear;
    margin-bottom: units(4);
    box-shadow: shadow($toast-shadow);

    &:not(.show) {
        opacity: 0;
    }

    &.hide {
        display: none;
    }

    @include media-breakpoint-up(md) {
        width: 464px;
    }

    .toast-icon {
        align-items: center;
        display: flex;
        padding-left: calc(#{units(4)} + #{units(5)});
        padding-right: units(4);
        background-size: units(505);
        background-repeat: no-repeat;
        background-position: center center;
    }

    .toast-message {
        padding: units(4);
        position: relative;
        width: 100%;

        .toast-close {
            @include button-unstyled;
            position: absolute;
            top: units(4);
            right: units(4);
            text-indent: -9999px;
            width: 24px;
            height: 24px;
            cursor: pointer;

            background-image: url($icons-folder-path+'/'+'close.svg');
            background-repeat: no-repeat;
        }

        p {
            margin: 0px;
            word-break: break-word;

            &:first-of-type {
                margin-right: units(6);
            }
        }
    }
}

@each $name,
$bgcolor in $toast-backgrounds {
    .toast-#{$name} {
        background-color: color($bgcolor);
    }
}

@each $name,
$iconcolor in $toast-icon-backgrounds {
    .toast-#{$name} .toast-icon {
        background-color: color($iconcolor);
    }
}

@each $name,
$iconname in $toast-icon-names {
    .toast-#{$name} .toast-icon {
        background-image: url($icons-folder-path+'/'+$iconname+'.svg');
    }
}