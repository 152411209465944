.details{
  display: block;
  margin-bottom: units(6);

  .details-summary::marker, .details-summary::-webkit-details-marker {
    display: none;
  }

  &[open] {
    .details-summary {
      margin-bottom: units(3);
      &:before {
        background-image: url($icons-folder-path+'/'+$details-button-open-icon);
        background-position: -10px -8px;
      }
    }
  }

  .details-summary {
    display: inline-block;
    position: relative;
    cursor: pointer;
    text-decoration: underline;

    &:before {
      content: "";
      display: inline-block;
      background-image: url($icons-folder-path+'/'+$details-button-closed-icon);
      background-position: -14px -8px;
      background-repeat: no-repeat;
      background-size: 3.4rem;;
      width: 1.6rem;
      height: 1.6rem;
      margin-right: units(2);
    }

    &:focus{
      @include focus();
    }
    .details-summary-text {
      text-decoration: underline;
    }
  }

  .details-text {
    padding: units(4);
    border-left: $details-panel-border-width solid color($details-panel-border-color);
    box-sizing: border-box;
    &[aria-hidden=true]{
      display: none;
    }
    p{
      margin-top: 0;
      margin-bottom: 1.6rem;
    }

    & > :last-child{
      margin-bottom: 0;
    }
  }
}
