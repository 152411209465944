/* hide the default checkbox */
input[type='checkbox'].form-toggle {
  @include sr-only();
}

input[type='checkbox'].form-toggle ~ .form-toggle-label {
  position: relative;
  display: inline-block;
  min-height: 20px; /* required in case the label is empty */
  padding-left: 68px;
  line-height: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-top: 8px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent
}

input[type='checkbox'].form-toggle ~ .form-toggle-label:before {
  content: $toggle-text-off;
  position: absolute;
  top: 4px;
  left: 4px;
  bottom: 0;
  border-radius: 50%;
  background-color: color($toggle-button-background-color);
  width: 29px;
  height: 29px;
  color: color('black');
  font-size: font-size('2xs');
  font-weight: font-weight("medium");
  padding-top: 5px;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
  z-index: 10;
  text-align: center;
  box-shadow: shadow($toggle-shadow);
}

input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label{
  cursor: not-allowed;
}
input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label:before,
input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label:hover:before,
input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label:active:before,
input[type='checkbox'].form-toggle:checked:disabled ~ .form-toggle-label:before{
  color: color($input-disabled-text-color);
}
input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label:after,
input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label:hover:after,
input[type='checkbox'].form-toggle:disabled ~ .form-toggle-label:active:after{
  opacity:0.5;
  background-color: color($input-disabled-text-color);
}

input[type='checkbox'].form-toggle:checked:disabled ~ .form-toggle-label:after,
input[type='checkbox'].form-toggle:checked:disabled ~ .form-toggle-label:hover:after,
input[type='checkbox'].form-toggle:checked:disabled ~ .form-toggle-label:active:after{
  opacity:0.5;
  background-color: color($toggle-button-on-default-background-color);
}

input[type='checkbox'].form-toggle ~ .form-toggle-label:after {
  content: "";
  position: absolute;
  left: 2px;
  top: 2px;
  bottom: 3px;
  width: 56px;
  height: 33px;
  border-radius: 20px;
  line-height: 1;
  text-align: center;
  background-color: color($toggle-default-background-color);
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  -o-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

input[type='checkbox'].form-toggle ~ .form-toggle-label:hover:after{
  background-color: color($toggle-hover-background-color);
}

input[type='checkbox'].form-toggle ~ .form-toggle-label:active:after{
  background-color: color($toggle-active-background-color);
}

input[type='checkbox'].form-toggle:checked ~ .form-toggle-label:after {
  background-color: color($toggle-button-on-default-background-color);
}

input[type='checkbox'].form-toggle:checked ~ .form-toggle-label:hover:after {
  background-color: color($toggle-button-on-hover-background-color);
}

input[type='checkbox'].form-toggle:checked ~ .form-toggle-label:active:after {
  background-color: color($toggle-button-on-active-background-color);
}

input[type='checkbox'].form-toggle:focus ~ .form-toggle-label:after {
  @include focus();
}

/* checked state */
input[type='checkbox'].form-toggle:checked ~ .form-toggle-label:before {
  content: $toggle-text-on;
  left: 26px;
}