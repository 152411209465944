@media print{
  html{
    font-family: Georgia, "Times New Roman", Serif;
    font-size: 48%;
  }
  body{
    display: block;
    min-height: 0;
  }
  .page-container{
    display: block;
  }

  fieldset{
    page-break-before: avoid;
    page-break-inside: auto;
    display: block;
    overflow: visible !important;
  }
  .form-group{
    page-break-inside: avoid;
  }
  .form-label{
    page-break-after: avoid;
  }

  // header
  .header {
    .logo, .portal-header .logo{
      height: auto;
      width: auto;
      background-image: none;
    }

    .portal-header{
      overflow:hidden;

      .portal-header-inner{
        display: block;
      }

      .portal-info{
        display: block;
        float: right;
      }
    }

    .solution-header{
      .solution-header-inner{
        display: block;
        overflow: hidden;
      }

      .solution-heading{
        display: inline-block;
      }
      .solution-info{
        display: block;
        float: right;
        margin-left: 0;
        text-align: right;

        @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
          margin-left: 0;
        }
      }
    }
  }

  // footer
  footer{
    .footer{
      margin-top: units(6);
      padding-bottom: 0;
      .inline-list > li{
        display: inline-block;
        + li{
          margin-left: units(4);
        }
      }
    }
  }

  // card
  .card{
    box-shadow: none;
  }

  // modal
  body.modal-active{
    @media print{
      > header{
        display: none
      }
      .page-container{
        display: none;
      }
      > footer{
        display: none;
      }
    }
  }

  body .modal {
    .modal__overlay {
      display: block;
      position: relative;
      background-color: transparent;
    }
    .bg-modal{
      background-color: transparent !important;
      background: transparent !important;
    }

    .modal__header {
      display: block;
    }

    .modal__footer{
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }

  // table
  .table.table--responsive-headers:not(.d-print-table),
  .table.table-sm-responsive-headers:not(.d-print-table),
  .table.table-md-responsive-headers:not(.d-print-table),
  .table.table-lg-responsive-headers:not(.d-print-table),
  .table.table-xl-responsive-headers:not(.d-print-table) {
    @include media-breakpoint-down(sm) {
      display: block;

      thead {
        display: none;
      }
      tr {
        border-bottom: border(20);
        &:last-child {
          border-bottom: 0;
        }
      }
      tr td, tr th {
        display: block;
        border: none !important;

        &[data-title]:not([data-title=""]):before {
          content: attr(data-title);
          text-align: left;
          display: block;
          font-weight: font-weight("semibold");
        }
      }

      &.table--borderless tbody tr {
        &:first-child {
          border-top: border($table-row-border);
        }
        &:last-child {
          border-bottom: border($table-row-border);
        }
        td:first-child, th:first-child {
          padding-left: 16px;
        }
      }
    }
  }

  .form-radio.radio-large:checked + label:after{
    border: units(3) solid color('black');
  }
  .form-radio:checked + label:after{
    border: units(2) solid color('black');
  }

  .form-checkbox:checked + label{
    &:before{
      background-color: transparent;
      border: 10px solid color('black');
    }
  }
  .form-checkbox.checkbox-large:checked + label{
    &:before{
      border: units(4) solid color('black');
    }
  }
}
