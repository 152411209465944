
.tabnav {
  @extend .accordion;
  @include media-breakpoint-up(md) {
    @include unstyled-list;
    list-style-type: none;
    padding: 0;
    position: relative;
    display: flex;
    flex-wrap: wrap;
  }
  > button {
    max-width: none;

    @extend .accordion-button;
    text-decoration: none;
    margin-top: units(3);
    margin-bottom: 0;
    @include media-breakpoint-up(md) {
      border: none;
    }

    &[aria-selected=true]{
      background-color: color($accordion-button-open-background-color);
      border-radius: $accordion-button-open-border-radius;
    }

    &[aria-selected=false]{
      background-image: url($icons-folder-path+'/'+$accordion-button-open-icon);
      background-repeat: no-repeat;
      background-size: units($accordion-button-icon-size, 'rem');
      border-radius: $accordion-button-border-radius;
      &:focus{
        background-color: color($tavnav-button-default-background-color);
      }
    }

    @include media-breakpoint-up(md) {
      display: inline-block;
      width: auto;
      color: color($tavnav-button-text-color);
      text-align: center;
      text-decoration: none;
      padding: 0px;
      background-color: transparent;
      border-radius: 0px;
      line-height: normal;
      padding-top: units(3);
      padding-bottom: units(3);
      padding-left: units(5);
      padding-right: units(5);
      margin: 0 units(2) units(2) 0px;
      text-decoration: underline;
      border-top: units(2) solid color('white');

      &[aria-selected=false]{
        background-color: color($tavnav-button-default-background-color);
        background-image: none;
        border-radius: 0px;
      }

      &:hover {
          background-color: color($tavnav-button-hover-background-color);
      }

      &:focus{
        outline: none;
        span{
          @include focus-outline();
        }
      }

      &[aria-selected=true] {
        background-color: color($tabnav-background-color);
        background-image: none;
        border: border($tabnav-border);
        padding-left: calc(#{units(5)} - 1px);
        padding-right: calc(#{units(5)} - 1px);
        border-bottom: 1px solid color($tabnav-background-color);
        border-radius: 0px;
        text-decoration: none;
        margin: 0 units(2) -1px 0px;
        padding-bottom: calc(#{units(3)} + 2px);
        position: relative;
        z-index: 350;
        span:not(.sr-only) {
          display: block;
        }
      }

    }
  }

  @include media-breakpoint-up(md) {
    > button:last-of-type{
      margin-right: units(3);
    }
  }

  &-panel{
    @extend .accordion-content;
    @include media-breakpoint-up(md) {
      display: none;
      border: border($tabnav-border);
      border-radius: 0;
      overflow: hidden;
      margin-bottom: calc(#{units(4)});
      background-color: transparent;
      position: relative;
      z-index: 300;

      &:focus{
        @include focus();
      }
    }
    &[aria-hidden=false]{
      display: block;
      order: 1000;
      width: 100%;
    }
  }
}
