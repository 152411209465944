.page-header{

}
.page-content{

}
.page-footer{

}


.page-header + .page-content{
    margin-top: 40px;
}
.page-content + .page-footer{
    margin-top: 40px;
}

.page-container{
    padding-top: units(5, 'rem');
    @include media-breakpoint-up(md) {
        padding-top: units(7, 'rem');
    }
}

//sticky footer
body {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
}
.page-container {
    flex: 1 0 auto;
}
