/*
----------------------------------------
GENERAL SETTINGS
----------------------------------------
*/

/*
----------------------------------------
Show compile warnings
----------------------------------------
Show Sass warnings when functions and
mixins use non-standard tokens.
AND
Show updates and notifications.
----------------------------------------
*/

$theme-show-compile-warnings: true !default;
$theme-show-notifications: true !default;

$theme-use-custom-font: false !default;

/*
----------------------------------------
Paths
----------------------------------------
*/
$font-path:                     '../fonts/IBMPlexSans/' !default;
$image-path:                    '../img' !default;
$icons-folder-path:             "../img/svg-icons" !default;

/*
----------------------------------------
Focus styles
----------------------------------------
*/

$theme-focus-color: 'gray-500' !default;
$theme-focus-offset: 1px !default;
$theme-focus-style: solid !default;
$theme-focus-width: 4px !default;

$theme-border-radius: 3px !default;
