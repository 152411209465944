.page-navigation{
    margin-top: units(7);
    display: flex;
    flex-direction: column;
    @include media-breakpoint-up(md) {
        display: inline-flex;
    }
    .button + .button{
        margin-top: units(3);
        margin-left: 0;
    }
}