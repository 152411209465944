// Buttons

a.button {
    display: inline-flex;
    padding-top: units(1);
    padding-left: calc(units(4) - $button-border-width);
    padding-right: calc(units(4) - $button-border-width);
    padding-bottom: 1px;
    line-height: units($button-line-height, 'rem');
}

.button {
    appearance: none;
    display: inline-flex;
    width: 100%;
    min-height: units(7);
    padding-top: units(1);
    padding-left: calc(units(4) - $button-border-width);
    padding-right: calc(units(4) - $button-border-width);
    padding-bottom: 1px;
    border-radius: $button-border-radius;
    border-width: $button-border-width $button-border-width $button-border-bottom-width $button-border-width;
    border-style: solid;
    border-color: color($button-primary-default-border-color);
    font-size: font-size('md');
    font-weight: font-weight("medium");
    color: color($button-primary-default-color);
    @include font-smoothing;
    line-height: units($button-line-height, 'rem');
    text-align: center;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    user-select: text;
    cursor: pointer;
    vertical-align: middle;

    & + button {
        margin-top: units(3);
    }

    @include media-breakpoint-up(sm) {
        width: auto;

        & + button {
            margin-top: 0;
        }
    }

    &.focus,
    &:focus {
        @include focus();
        color: color($button-primary-focus-color);
    }

    //############ Primary ###############
    &.button-primary {
        color: color($button-primary-default-color);
        background-color: color($button-primary-default-background-color);
        border-color: color($button-primary-default-border-color);

        &:hover,
        &.button-hover {
            background-color: color($button-primary-hover-background-color);
            border-color: color($button-primary-hover-border-color);
        }

        &.focus,
        &:focus {
            background-color: color($button-primary-focus-background-color);
            border-color: color($button-primary-focus-border-color);
        }

        &:active,
        &.button-active {
            background-color: color($button-primary-active-background-color);
            border-color: color($button-primary-active-border-color);
        }

        &:disabled,
        &.disabled {
            opacity: $button-disabled-opacity;
            box-shadow: none !important;
            cursor: not-allowed;

            &:hover {
                color: color($button-primary-hover-color) !important;
                background-color: color($button-primary-default-background-color) !important;
                border-color: color($button-primary-default-border-color) !important;
                opacity: $button-disabled-opacity !important;
                box-shadow: none !important;
            }
        }
    }

    //############ Secondary ##############
    &.button-secondary {
        color: color($button-secondary-default-color);
        background-color: color($button-secondary-default-background-color);
        border-color: color($button-secondary-default-border-color);

        &:hover,
        &.button-hover {
            color: color($button-secondary-hover-color);
            background-color: color($button-secondary-hover-background-color);
            border-color: color($button-secondary-hover-border-color);
        }

        &.focus,
        &:focus {
            color: color($button-secondary-focus-color);
            background-color: color($button-secondary-focus-background-color);
            border-color: color($button-secondary-focus-border-color);
        }

        &:active,
        &.button-active {
            color: color($button-secondary-active-color);
            background-color: color($button-secondary-active-background-color);
            border-color: color($button-secondary-active-border-color);
        }

        &:disabled,
        &.disabled {
            opacity: $button-disabled-opacity;
            box-shadow: none !important;
            cursor: not-allowed;
            border-color: color($button-secondary-disabled-border-color) !important;

            &:hover {
                color: color($button-secondary-default-color) !important;
                background-color: color($button-secondary-default-background-color) !important;
                border-color: color($button-secondary-disabled-border-color) !important;
                opacity: $button-disabled-opacity !important;
                box-shadow: none !important;
            }
        }
    }

    //############### tertiary ############
    &.button-tertiary {
        border-color: color($button-tertiary-default-border-color);
        background-color: color($button-tertiary-default-background-color);
        color: color($button-tertiary-default-color);
        font-weight: font-weight("normal");

        &:hover,
        &.button-hover {
            border-color: color($button-tertiary-hover-border-color);
            background-color: color($button-tertiary-hover-background-color);
            color: color($button-tertiary-hover-color);
        }

        &.focus,
        &:focus {
            border-color: color($button-tertiary-focus-border-color);
            background-color: color($button-tertiary-focus-background-color);
            color: color($button-tertiary-focus-color);
        }

        &:active,
        &.button-active {
            border-color: color($button-tertiary-active-border-color);
            background-color: color($button-tertiary-active-background-color);
            color: color($button-tertiary-active-color);
        }

        &:disabled,
        &.disabled {
            opacity: $button-disabled-opacity;
            box-shadow: none !important;
            cursor: not-allowed;

            &:hover {
                border-color: color($button-tertiary-default-border-color) !important;
                background-color: color($button-tertiary-default-background-color) !important;
                color: color($button-tertiary-default-color);
                font-weight: font-weight("normal") !important;
                opacity: $button-disabled-opacity;
                box-shadow: none !important;
            }
        }
    }

    //############ Button quaternary - button styled as a link #################
    &.button-quaternary {
        color: color($button-quaternary-default-color);
        background-color: color($button-quaternary-default-background-color);
        border-color: transparent;
        text-decoration: none;
        font-weight: font-weight("normal");
        padding-left: calc(8px - #{$button-border-width});

        &:hover,
        &.button-hover {
            background-color: color($button-quaternary-hover-background-color);
            box-shadow: none;
            border-bottom-color: transparent;
        }

        &.focus,
        &:focus {
            color: color($button-quaternary-default-color);
            box-shadow: none;
            border-bottom-color: transparent;
            background-color: color($button-quaternary-focus-background-color);
        }

        &:active,
        &.button-active {
            background-color: color($button-quaternary-active-background-color);
            box-shadow: none;
            border-bottom-color: transparent;
        }

        &:disabled,
        &.disabled {
            opacity: $button-disabled-opacity;
            box-shadow: none !important;
            cursor: not-allowed;

            &:hover {
                color: color($button-quaternary-default-color) !important;
                background-color: color($button-quaternary-default-background-color) !important;
                font-weight: font-weight("normal") !important;
                opacity: $button-disabled-opacity !important;
                box-shadow: none !important;
            }
        }

        .icon-svg {
            margin-right: units(2);
            min-width: units($button-line-height, 'rem');
        }
    }

    &.button-small {
        padding: calc(#{units(1)}) calc(#{units(4)} - #{$button-border-width}) 0px;
        font-size: font-size($typography-small-text-font-size);
        min-height: 24px;
    }

    .icon-svg {
        margin-right: units(3);
        fill: currentColor;
        min-width: units($button-line-height, 'rem');
    }
}

@mixin button-unstyled {
    background-color: transparent;
    border: 0;
    border-radius: 0;
    font-weight: font-weight("normal");
    margin: 0;
    padding: 0;
    text-align: left;
    -webkit-font-smoothing: auto;
    color: inherit;
    min-height: auto;

    &:hover,
    &:focus {
        background-color: transparent;
        color: inherit;
    }
}

.button-unstyled {
    @include button-unstyled;
    width: auto;
}

.button + .button {
    @include media-breakpoint-up(md) {
        margin-left: 8px;

        &:first-child {
            margin-left: 0;
        }
    }
}

.button-column {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .button {
        margin-top: 8px;
        margin-left: 0;

        &:first-child {
            margin-top: 0;
        }
    }
}