//
// Generates utility classes to control width in percent. 
// Example: .w-percent-100 .w-percent-md-30
//

$percent-widths: (
  10: 10%,
  20: 20%,
  30: 30%,
  40: 40%,
  50: 50%,
  60: 60%,
  70: 70%,
  80: 80%,
  90: 90%,
  100: 100%,
) !default;

@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    @each $name, $size in $percent-widths { 
      $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
      .w-percent#{$infix}-#{$name}         { width: $size !important;}
    }
  }
} 