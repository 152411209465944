// Header variables ---------- //
// z-indexes - TODO refactor to a site-wide z-index management approach
$z-index-header:  7000;
$z-index-overlay: 8000;
$z-index-nav:     9000;

// Header ------------- //

.header {
  width: 100%;

  .logo{
    background-image: url($image-path+'/'+$header-portal-logo);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center left;
    display: inline-block;
    height: 60px;
    width: 200px;
    max-width: 60%;
  }

  .button{
    width: 100%;
    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      width: auto;
    }
  }
  h1,h2,h3,h4,h5,h6,p{
    margin-top: 0;
    margin-bottom: 0;
  }
  @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
    .form-select, .form-input{
      margin-top: 0;
      margin-bottom: 0;
    }
    .form-select, .form-input{
      width: auto;
    }
  }

  .button-menu-open {
    width: auto;
    white-space: nowrap;
    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      display: none;
    }
  }
  .button-menu-close {
    width: unset;
    margin-left: auto;
    display: flex;
    align-items: center;
    margin-right: units(4);
    margin-top: 0px;
    margin-bottom: 0;
    .icon-svg{
      margin: 0 8px 0 0;
    }
    span{
      position: relative;
      top: 1px;
    }
    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      display: none;
    }
  }

  .portal-header{
    background-color: color($header-portal-background-color);
    border-bottom: border($header-portal-border);
    width: 100%;
    &.portal-header-compact{
      font-size: font-size($typography-small-text-font-size);
      line-height: units($typography-small-text-line-height, 'rem');
      p {
        font-size: font-size($typography-small-text-font-size);
        line-height: units($typography-small-text-line-height, 'rem');
      }
      .portal-info .user{
        max-width: none;
      }


      .portal-header-inner{
        padding-top: units(2);
        padding-bottom: calc(#{units(2)} - 1px);
      }

      .logo{
        max-height: 24px;
        width: $header-portal-compact-logo-width;
        margin-top: units(3);
        margin-bottom: units(3);
      }
    }
    .portal-header-inner{
      width: 100%;
      padding-top: calc(#{units(4)} - 1px);
      padding-bottom: calc(#{units(4)} - 1px);
      display: flex;
      align-items: center;
      @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
        flex-direction: row;
      }
    }

    .portal-info{
      display: none;
      @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
        margin-left: auto;
        display: flex;
        align-items: center;

        & > *{
          margin-left: units(4);
        }
      }
      .user{
        text-align: right;
        max-width: 500px;
      }
      .button{
        white-space: nowrap;
      }
    }
  }

  .solution-header{
    border-bottom: border($header-border-bottom);
    background-color: color($header-solution-background-color);

    @include media-breakpoint-up(lg) {
      border-bottom: border($header-solution-border);
    }

    width: 100%;
    &.header-end{
      border-bottom: border($header-border-bottom);
    }

    .solution-header-inner{
      display: flex;
      align-items: center;
      flex-direction: row;
      padding-top: calc(#{units(4)} - 1px);
      padding-bottom: calc(#{units(4)} - 1px);
    }

    .solution-heading{
      a, h1, .g1{
        font-size:font-size($header-solution-text-size);
        line-height: units($header-solution-line-height, 'rem');
        font-weight: font-weight("semibold");
        color: color($header-solution-text-color);
        text-decoration: none;
        display: inline-block;
        @include media-breakpoint-up(md) {
          font-size: font-size($header-solution-text-size-desktop);
          line-height: units($header-solution-line-height-desktop, 'rem');
        }
      }
      p, h2, h3, h4, h5, .h2, .h3, .h4, .h5{
        color: color($header-solution-text-color);
      }
      .function-link{
        color: color($header-solution-text-color);
        &:hover, &:focus, &:visited{
          color: color($header-solution-text-color);
        }
      }
    }
    .solution-info{
      display: none;
      @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
        margin-left: auto;
        text-align: right;
        max-width: 60%;
        display: block;
      }
    }
  }
  .authority-name{
    color: color($header-solution-authority-name);
    font-weight: font-weight("semibold");
    font-size: font-size($header-solution-authority-name-font-size);
    line-height: 2.4rem;
    margin-top: 0;
    margin-bottom: 0;
  }
  .button-contact{
    display: inline-flex;
    .icon-svg{
      position: relative;
      top: -1px;
    }
  }

  .portal-info-mobile{
    padding: units(5) units(4);
    border-bottom: border(20);
    text-align: left;
    p{
      margin-bottom: units(3);
    }
    .button-signin, .button-signout{
      margin-top: 8px;
    }
    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      display: none;
    }
  }

  .solution-info-mobile{
    padding: units(5) units(4);
    p{
      margin-bottom: units(3);
      color: color($header-solution-info-mobile-text-color);
      &:last-child{
        margin-bottom: 0;
      }
    }
    .function-link{
      color: color($header-solution-info-mobile-text-color);
      &:hover, &:focus, &:visited{
        color: color($header-solution-info-mobile-text-color);
      }
    }
    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      display: none;
    }
  }


  .nav {
    $sliding-panel-width: 85%;
    @keyframes slidein-left {
      from {
        transform: translateX($sliding-panel-width);
      }

      to {
        transform: translateX(0);
      }
    }
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: auto;
    background-color: color($header-navigation-background-color);
    display: none;
    flex-direction: column;
    overflow-y: auto;
    padding: 0;
    padding-top: units(4);
    width: $sliding-panel-width;
    z-index: $z-index-nav;

    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      border-left: none;
      display: block;
      overflow-y: visible;
      position: static;
      width: auto;
    }

    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      padding: 0;
      width: 100%;
    }

    &.is-visible {
      animation: slidein-left 0.3s ease-in-out;
      display: block;
    }
  }

  .nav-actions{
    margin-top: units(3);
    padding: 0 units(4);
    &:first-child{
      margin-top: 0;
    }

    & > li{
      margin-top: units(3);
      &:first-child{
        margin-top: 0;
      }
    }

    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      margin-top: 0;
      margin-left: auto;
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      flex-wrap: wrap;
      margin-right: -4px;
      padding: 0;
      & > li, & > li:first-child{
        margin: calc(#{units(3)} - 2px) 4px;
      }
    }
  }

  .function-link:not(.icon-link):not(.button-menu-close):not(.button-menu-open) {
    @include media-breakpoint-down(#{$nav-responsive-breakpoint}) {
      display: block;
      margin-top: 8px;
      .icon-svg {
        float: left;
        margin-left: 0;
        margin-right: 4px;
      }
    }
  }
  .function-link + .function-link{
    @include media-breakpoint-up(#{$nav-responsive-breakpoint}) {
      margin-left: units(4);

    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: color('black');
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  visibility: hidden;
  z-index: $z-index-overlay;

  &.is-visible {
    opacity: 0.75;
    visibility: visible;
  }
}
