body.modal-open{
  overflow: hidden;
}
.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: color($modal-overlay-background-color);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 8888;
  padding: 16px;
}
.fds-modal {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  margin: units(4);

  &[aria-hidden="false"] {
    display: flex;
  }

 .modal-content {
    align-self: center;
    max-height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: color($modal-background-color);
    box-shadow: shadow($modal-shadow);
    max-width: 800px;
    width: 100%;
    position: relative;
    margin: 24px auto 24px;
  }

  .modal-header {
    padding: 16px 24px 0px 24px;
    @include media-breakpoint-up(md) {
      padding: units(7) units(7) units(3) units(7);
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: color($modal-header-background-color);
    color: color($modal-header-text-color);
    @include media-breakpoint-up(md) {
      padding-right: 48px;
    }

    h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
      color: color($modal-header-text-color);
    }
  }
  .modal-title {
    margin: units(5) units(8) units(3) 0;
    
    @include media-breakpoint-up(md) {
      margin: 0 units(8) 0 0;
    }
  }

  .modal-body {
    background-color: color($modal-body-background-color);
    color: color($modal-body-text-color);
    padding: 0px 24px;
    @include media-breakpoint-up(md) {
      padding: units(3) units(7) units(3) units(7);
    }
    & > *:first-child{
      margin-top: 0;
    }

    p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
      color: color($modal-body-text-color);
    }
  }

  .modal-footer{
    padding: 0px units(5) units(5) ;
    @include media-breakpoint-up(md) {
      padding: units(3) units(7) units(7) units(7);
    }
    background-color: color($modal-footer-background-color);
    color: color($modal-footer-text-color);
    @include media-breakpoint-up(lg) {
      display: flex;
    }
    .button {
      margin-top: units(3);
      margin-right: units(4);
    }
    
    p, h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6{
      color: color($modal-footer-text-color);
    }
  }

  .modal-close {
    position: absolute;
    top: 8px;
    right: 8px;
    width: inherit;
    color: color($modal-header-close-color);
    @include media-breakpoint-up(md) {
      top:units(5);
      right: units(6);
    }

    &:hover, &:visited, &:focus{
      color: color($modal-header-close-color);
    }
  }

  /**************************\
        Animation Style
    \**************************/
  @keyframes modalfadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }

  @keyframes modalfadeOut {
    from { opacity: 1; }
    to { opacity: 0; }
  }

  @keyframes modalslideIn {
    from { transform: translateY(15%); }
    to { transform: translateY(0); }
  }

  @keyframes modalslideOut {
    from { transform: translateY(0); }
    to { transform: translateY(-10%); }
  }




  &[aria-hidden="false"] .modal-content {
    animation: modalslideIn .3s cubic-bezier(0, 0, .2, 1);
  }

  &[aria-hidden="true"] .modal-content {
    animation: modalslideOut .3s cubic-bezier(0, 0, .2, 1);
  }

}
