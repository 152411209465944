@use 'sass:math';
a.skipnav {
  color: color($skipnav-text-color);
  left: 0;
  padding: 1rem 1.5rem;
  position: absolute;
  top: -4.2rem;
  transition: all 0.2s ease-in-out;
  z-index: 8000;
  max-width: none;
  outline-offset: 0;

  &:focus {      
    @include focus-outline();
    background-color: color($skipnav-background-color);
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.2s ease-in-out;
    padding: 1rem math.div(math.div($grid-gutter-width, 2), 2);
    margin-top: calc(#{units(4)} - 1px);
    margin-left: math.div(math.div($grid-gutter-width, 2), 2);
  }
}

#main-content:focus{
  @include focus-outline();
}
