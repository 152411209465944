.language-switcher{
  border-top: border($language-switcher-border);
  background-color: color($language-switcher-background-color);
  padding-top: #{units(5)};
  @include media-breakpoint-up(md) {
    margin-top: -#{units(5)};
    padding-top: #{units(4)};
  }

    ul{
      @include unstyled-list;
      max-width: none !important;
    > li{
      margin-bottom: units(305);
      padding-left: units(6);


      @include media-breakpoint-up(md) {
        display: inline-block;
        padding-left: 0;
        &::after {
          display: inline-block;
          content: "|";
          margin-left: calc(#{units(4)} - 1px);
          margin-right: #{units(4)};
        }
        &:last-of-type::after{
          content: "";
        }
      }

      a, a:hover, a:visited, a:active, a:focus{
        color: color($language-switcher-text-color);
        display: inline-block;


        svg{
          margin-top: -#{units(2)};
          margin-right: #{units(3)};
          display: inline-block;
        }
      }

      &:first-of-type{
        padding-left: 0px;

        a, a:hover, a:visited, a:active, a:focus {
          padding-left: 0;
          font-weight: font-weight("semibold");
          color: color($language-switcher-active-text-color);
          text-decoration: none;
          border-left: 0;
        }
      }

      &:last-of-type {
        a, a:hover, a:visited, a:active, a:focus {
          padding-right: 0;
        }
      }
    }
  }
}
