.search {
  display: flex;
  
  .form-input{
    background-color: color($search-background-color);
    margin-top: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    &:focus{
      z-index: 1;
    }
  }

  .button-search{
    background-color: color($button-search-default-background-color);
    border: border($input-border);
    border-left: none;
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
    color: color($button-search-text-color);
    font-weight: font-weight("normal");
    width: inherit; //not 100& width on mobile
    &:hover{
      background-color: color($button-search-hover-background-color);
    }
  }
}

// Extra specificity to override rules set in reset.css.
input[type=search] { /* stylelint-disable-line selector-no-qualifying-type */
  box-sizing: border-box;
  appearance: none;
  -webkit-appearance: none; //Fixes border-radius bug on iPhone X Safari. 
}
